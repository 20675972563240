<template>
  <li class="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200">
    <div class="w-full flex items-center justify-between p-6 space-x-6">
      <div class="flex-1 truncate">
        <div class="flex items-center space-x-3">
          <h3 class="text-gray-900 text-sm font-medium truncate">
            {{ user.name }}
          </h3>
        </div>
        <p class="mt-1 text-gray-500 text-sm truncate">
          {{ user.email }}
        </p>
        <span
          class="
            mb-1
            inline-block
            px-2
            py-0.5
            text-green-800 text-xs
            font-medium
            bg-green-100
            rounded-full
          "
          >{{ user.user_type }}</span
        >
        <span
          v-if="user.outreach"
          class="
            mb-1
            inline-block
            px-2
            py-0.5
            text-green-800 text-xs
            font-medium
            bg-green-100
            rounded-full
          "
          >outreach</span
        >
      </div>
      <img
        class="w-10 h-10 bg-gray-300 rounded-full flex-shrink-0"
        :src="`https://ui-avatars.com/api/?name=${user.name}&background=1F2937&color=FFF`"
        alt=""
      />
    </div>
    <div>
      <div class="-mt-px flex divide-x divide-gray-200">
        <div class="w-0 flex-1 flex">
          <router-link
            :to="`/user/${user.id}`"
            class="
              relative
              -mr-px
              w-0
              flex-1
              inline-flex
              items-center
              justify-center
              py-4
              text-sm text-gray-700
              font-medium
              border border-transparent
              rounded-bl-lg
              hover:text-gray-500
            "
          >
            <!-- <svg
              class="w-5 h-5 text-gray-400"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
              ></path>
            </svg> -->
            <span class="ml-3">Edit</span>
          </router-link>
        </div>
        <div class="-ml-px w-0 flex-1 flex">
          <button
            @click="loginAsOpened = true"
            class="
              relative
              w-0
              flex-1
              inline-flex
              items-center
              justify-center
              py-4
              text-sm
              focus:outline-none
              text-indigo-600
              font-medium
              border border-transparent
              rounded-br-lg
              hover:text-indigo-500
            "
          >
            <!-- <svg
              class="w-5 h-5 text-red-400"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
              ></path>
            </svg> -->
            <span class="ml-3">Login As</span>
          </button>
        </div>
        <div class="-ml-px w-0 flex-1 flex">
          <button
            @click="this.$emit('delete', user)"
            class="
              relative
              w-0
              flex-1
              inline-flex
              items-center
              justify-center
              py-4
              text-sm
              focus:outline-none
              text-red-600
              font-medium
              border border-transparent
              rounded-br-lg
              hover:text-red-500
            "
          >
            <!-- <svg
              class="w-5 h-5 text-red-400"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
              ></path>
            </svg> -->
            <span class="ml-3">Delete</span>
          </button>
        </div>
      </div>
    </div>
  </li>

  <LoginAs
    :id="user.id"
    :name="user.name"
    :open="loginAsOpened"
    @close="loginAsOpened = false"
  />
</template>

<script>
import LoginAs from "@/widgets/users/login_as.vue";

export default {
  components: {
    LoginAs,
  },
  data() {
    return {
      loginAsOpened: false,
    };
  },
  emits: ["edit", "delete"],
  props: {
    user: {
      type: Object,
    },
  },
};
</script>

<style></style>
