<template>
  <div class="container mx-auto px-4 sm:px-6 lg:px-8">
    <ConfirmationDialog
      :open="openDeleteDialog"
      :title="userToDelete ? 'Delete ' + userToDelete.name : ''"
      :loading="deleting"
      text="Are you sure you want to delete this account? All data will be permanently removed from our servers forever. This action cannot be undone."
      buttonLbl="Delete"
      @cancel="openDeleteDialog = false"
      @confirm="deleteUserAction"
    />

    <div class="bg-gray-50 sm:rounded-lg">
      <div class="px-4 py-5 sm:p-6">
        <h3 class="text-lg leading-6 font-medium text-gray-900">
          Add more users
        </h3>
        <div class="mt-2 max-w-xl text-sm text-gray-500">
          <p>
            The users you add can use there email & password to login and use
            the platform.
          </p>
        </div>
        <div class="mt-5">
          <router-link
            to="/users/add"
            class="
              inline-flex
              items-center
              px-4
              py-2
              border border-gray-300
              shadow-sm
              font-medium
              rounded-md
              text-gray-700
              bg-white
              hover:bg-gray-50
              focus:outline-none
              focus:ring-2
              focus:ring-offset-2
              focus:ring-indigo-500
              sm:text-sm
            "
          >
            Add new user
          </router-link>
        </div>
      </div>
    </div>

    <ul
      v-if="!loading"
      class="
        grid grid-cols-1
        gap-6
        sm:grid-cols-2
        md:grid-cols-2
        lg:grid-cols-3
        xl:grid-cols-4
        mt-8
      "
    >
      <UserItem
        v-for="user in users"
        :key="user.id"
        :user="user"
        @delete="deleteUserClicked"
      />
    </ul>
    <div v-if="users.length !== 0" class="mx-4 my-4">
      <Pagination />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import UserItem from "@/widgets/users/UserItem.vue";
import ConfirmationDialog from "@/widgets/common/ConfirmationDialog.vue";
import Pagination from "@/views/users/views/users_pagination.vue";

export default {
  components: {
    UserItem,
    ConfirmationDialog,
    Pagination,
  },
  async created() {
    this.$Progress.start();
    await this.listUsers();
    this.$Progress.finish();
  },
  data() {
    return {
      openDeleteDialog: false,
      userToDelete: null,
    };
  },
  computed: {
    ...mapGetters("users", ["errorMessage", "loading", "users", "deleting"]),
  },
  methods: {
    deleteUserClicked(user) {
      this.userToDelete = user;
      this.openDeleteDialog = true;
    },
    async deleteUserAction() {
      this.$Progress.start();
      await this.deleteUser(this.userToDelete.id);
      await this.listUsers();
      this.$Progress.finish();
      this.openDeleteDialog = false;
    },
    ...mapActions("users", ["listUsers", "deleteUser"]),
  },
};
</script>

<style></style>
